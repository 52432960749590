<template>
  <!-- main container -->
  <div :class="isDark ? '' : 'dark'">
    <div :class="{ themeYellowContrast: isBtnContrast ? isBtnContrast : '' }">
      <div>
        <nav>
          <div
            class="yellowContrastBgBlack md:flex justify-around md:justify-between p-4 uppercase font-medium dark:border-b-2 dark:border-b-[#007cf7] bg-gradient-to-r from-[#ffffff] via-[#8dadcf] to-[#02254d] dark:bg-gradient-to-r dark:from-[#ffffff] dark:via-[#aeaeb3] dark:to-[#181919]"
          >
            <router-link
              class="hover:text-sky-500 transition ease-in-out duration-500"
              to="/home"
            >
              <img
                class="yellowContrastBgYellow w-40 lg:w-44"
                src="@/assets/logo.png"
                alt="logo firmy"
              />
            </router-link>
            <span>
              <div
                class="hidden md:flex justify-items-stretch text-neutral-900 font-bold md:text-sm 2md:text-lg mt-2"
              >
                <router-link
                  class="md:px-4 hover:text-sky-500 lg:tracking-[.10em] transition ease-in-out duration-300"
                  to="/home"
                  >Home
                </router-link>
                <router-link
                  class="md:px-4 hover:text-sky-500 lg:tracking-[.10m]] transition ease-in-out duration-300"
                  to="/about"
                  >O Firmie</router-link
                >
                <router-link
                  class="md:px-4 hover:text-sky-500 lg:tracking-[.10em] transition ease-in-out duration-300"
                  to="/services"
                  >Usługi</router-link
                >

                <router-link
                  class="md:px-4 hover:text-sky-500 lg:tracking-[.10em] transition ease-in-out duration-300"
                  to="/contact"
                  >Kontakt</router-link
                >

                <router-link
                  class="md:px-4 hover:text-sky-500 lg:tracking-[.10em] transition ease-in-out duration-300"
                  to="/links"
                  >Linki</router-link
                >
              </div>
            </span>

            <TheNawigationVue></TheNawigationVue>

            <router-link
              class="yellowContrastBgYellow hidden md:inline-flex mt-2 hover:text-sky-400 text-neutral-100 transition ease-in-out duration-500"
              to="/contact"
              >+48 533 079 310</router-link
            >
          </div>
        </nav>

        <!-- <main class="inset-0 dark:bg-[#181919]">
          <div :class="currentSize">
            <transition
              name="fade"
              mode="out-in"
              :duration="{ enter: 500, leave: 500 }"
              :css="{ opacity: '0' }"
            >
              <router-view></router-view>
            </transition>
          </div>
        </main> -->

        <main class="inset-0 dark:bg-[#181919]">
          <div :class="currentSize">
            <router-view v-slot="{ Component, route }">
              <transition name="route" mode="out-in" :key="route.route">
                <component :is="Component"></component>
              </transition>
            </router-view>
          </div>
        </main>

        <footer>
          <div
            class="bg-white z-20 mb-0 border-t-2 shadow-[0_13px_20px_15px_rgba(0,0,0,0.20)] border-t-orange-600"
          >
            <div class="lg:flex justify-between place-items-center">
              <div
                class="flex flex-col-reverse lg:flex-row-reverse xl:justify-around border-b-2 border-b-grey-500 lg:border-0 dark:text-slate-50 md:dark:text-stone-900 dark:bg-[#181919] md:dark:bg-slate-50"
              >
                <div
                  class="flex justify-center space-x-4 md:space-x-8 md:ml-16 pt-3 lg:pt-5 h-14 xs:text-sm s:text-lg text-xl md:text-xl md:font-normal"
                >
                  <router-link
                    class="hover:text-sky-400 transition ease-in-out duration-300"
                    to="/home"
                    >Home
                  </router-link>

                  <router-link
                    class="hover:text-sky-400 transition ease-in-out duration-300"
                    to="/about"
                    >O Firmie</router-link
                  >

                  <router-link
                    class="hover:text-sky-400 transition ease-in-out duration-300"
                    to="/services"
                    >Usługi</router-link
                  >

                  <router-link
                    class="hover:text-sky-400 transition ease-in-out duration-300"
                    to="/contact"
                    >Kontakt</router-link
                  >

                  <router-link
                    class="md:px-4 hover:text-sky-500 lg:tracking-[.10em] transition ease-in-out duration-300"
                    to="/links"
                    >Linki</router-link
                  >
                </div>

                <div
                  class="flex justify-between items-center text-base lg:text-base pl-2 lg:pl-0"
                >
                  <div class="pr-3">
                    <!-- size button -->
                    <button>
                      <div
                        @click="sizeBtn"
                        class="mr-2 lg:ml-2 w-12 h-[50px] hover:text-sky-400 active:bg-sky-200 transition ease-in-out duration-300"
                      >
                        <i class="fa-thin fa-a font-medium"></i>
                        <p>size</p>
                      </div>
                    </button>
                    <!-- contrast mode -->
                    <button @click="isBtnContrast = !isBtnContrast">
                      <div
                        class="pl-4 pr-5 h-12 hover:text-sky-400 transition ease-in-out duration-300"
                      >
                        <i
                          v-if="isBtnContrast"
                          class="fa-solid fa-circle-half-stroke"
                        ></i>
                        <i v-else class="fa-solid fa-circle-half-stroke"></i>
                        <p>contrast</p>
                      </div>
                    </button>
                  </div>
                  <!-- dark mode -->
                  <button
                    @click="isDark = !isDark"
                    class="mr-2 pl-3 hover:text-sky-400 transition ease-in-out duration-500"
                  >
                    <div>
                      <i v-if="isDark" class="fa-solid fa-toggle-off"></i>
                      <i v-else class="fa-solid fa-toggle-on text-sky-400"></i>
                      <p>dark mode</p>
                    </div>
                  </button>
                </div>
              </div>

              <div class="px-1 pt-1 h-10 lg:h-12 lg:w-[470px] xl:w-[32rem]">
                <img class="" src="@/assets/fotoFooterUe.jpg" alt="UE" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import TheNawigationVue from "@/components/TheNawigation.vue";

// import { ref } from "vue";

export default {
  components: {
    TheNawigationVue,
  },
  data() {
    return {
      isDark: true,
      isBtnContrast: false,
      currentSize: "", // to jest zamiast flase zeby zostawic domyślny pusty widok
      sizeClasses: ["", "fontSizeUpMedium", "fontSizeUpBig"],
    };
  },
  methods: {
    sizeBtn: function () {
      let lenght = this.sizeClasses.length - 1;
      // prettier-ignore
      this.currentIndex = this.sizeClasses.findIndex(
        value => value === this.currentSize
      );
      let nextIndex = this.currentIndex + 1;
      if (nextIndex > lenght) {
        nextIndex = 0;
      }

      this.currentSize = this.sizeClasses[nextIndex];
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bgImage {
  background-image: url(@/assets/mainBgTransparent.png);
}
/*color for active links*/
.vue-active-links {
  color: #167ece;
}

/* classes for fontSize button */
.fontSizeUpMedium {
  font-size: 20px;
}
.fontSizeUpBig {
  font-size: 22px;
}

/* margin between tables*/
.fontSizeUpMedium .classMarginBottom {
  margin-bottom: 350px;
}
.fontSizeUpMedium .classMarginBottomTwo {
  margin-bottom: 500px;
}

/* margin top for last div*/
.fontSizeUpMedium .classMarginTop {
  margin-top: 50px;
}

/* margin between tables*/
.fontSizeUpBig .classMarginBottom {
  margin-bottom: 450px;
}
.fontSizeUpBig .classMarginBottomTwo {
  margin-bottom: 665px;
}

/* margin top for last div*/
.fontSizeUpBig .classMarginTop {
  margin-top: 100px;
}

/* colors theme for contrasting background*/
.themeYellowContrast {
  background-color: #221f21;
  color: #fffe01;
}
.themeYellowContrast div {
  color: #fffe01;
  box-shadow: none;
  background-image: none;
  background-color: #221f21;
}
.themeYellowContrast li {
  color: #fffe01;
}
.themeYellowContrast span {
  color: #fffe01;
  background-image: none;
  background-color: #010001;
}
.themeYellowContrast tr {
  background-color: #010001;
  color: #0b62a5;
}
.themeYellowContrast td {
  background-color: #010001;
  color: #fffe01;
}
.themeYellowContrast section {
  background-color: #221f21;
}
.themeYellowContrast p {
  color: #fffe01;
}
.themeYellowContrast .yellowContrastBgYellow {
  background-color: #fffe01;
  color: #221f21;
}
.themeYellowContrast .yellowContrastBgBlack {
  background-color: #010001;
  color: #fffe01;
}
.themeYellowContrast .yellowContrastNone {
  background-color: none;
}

/* transition for main*/
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.5s ease-in-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.5s ease-in-out;
}
</style>
