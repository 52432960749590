<template>
  <div
    class="not-italic flex flex-col justify-items-center fixed text-gray-900 bg-gray-400/90 h-80 md:h-64 w-full top-0 left-0 right-1 z-50 text-base py-10 px-5"
    :class="containerPosition"
    v-if="isOpen"
  >
    <div class="cookie_content">
      <slot name="message">
        {{ message }}
      </slot>
      <div class="pt-10">
        <button class="bg-blue-700/50 py-2 px-4 mr-4" @click="accept">
          {{ buttonTextAccept }}
        </button>
        <button class="bg-blue-700/50 py-2 px-4 mr-4" @click="deny">
          {{ buttonTextDeny }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieMessage",
  props: {
    buttonTextAccept: {
      type: String,
      default: "Akceptuje",
    },
    buttonTextDeny: {
      type: String,
      default: "Nie akceptuje",
    },

    message: {
      type: String,
      default:
        " Ta strona korzysta z plików cookies. Zgodnie z naszą polityką\
            prywatności korzystając z serwisu wyrażasz na to zgodę. W każdej\
            chwili możesz dokonać zmiany ustawień dotyczących plików cookies w swojej\
            przeglądarce.",
    },
    position: {
      type: String,
      default: "top",
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    containerPosition() {
      return `cookie--${this.position}`;
    },
  },
  created() {
    if (!this.getGDPR() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDPR() {
      return localStorage.getItem("GDPR:accepted", true);
    },
    accept() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", true);
    },
    deny() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", false);
    },
  },
};
</script>
