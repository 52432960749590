<template>
  <header
    class="relative flex items-center justify-center xs:min-h-[88vh] s:min-h-[91vh] sm:min-h-[93vh] md:min-h-[95vh] lg:min-h-[84vh] xl:min-h-[87vh] 2xl:min-h-0 overflow-hidden"
  >
    <div>
      <div
        class="relative md:left-8 lg:-left-4 w-44 md:w-80 ml:w-96 lg:w-96 xl:w-[40rem] h-32 sx:-top-0 md:-top-16 lg:-top-12 xl:-top-20 z-30"
      >
        <headLine />
      </div>
      <div
        class="relative -bottom-2 2md:-bottom-24 lg:-bottom-12 xl:-bottom-28 md:left-8 2md:text-[40px] lg:-left-4 md:tracking-[.15em] uppercase sx:text-sm s:text-lg md:text-3xl lg:text-3xl xl:text-5xl text-white z-30"
      >
        <p class="pb-3 s:pb-2 2md:pb-8 lg:pb-4">Wsparcie informatyczne</p>
        <p>Firm i klientów indywidualnych</p>
        <cookie-message />
      </div>
      <div
        class="relative z-30 top-16 2md:top-48 lg:top-28 xl:top-52 -right-28 md:-right-80 lg:-right-[500px] xl:left-[43rem] p-2 pl-4 lg:py-3 xl:py-6 w-28 md:h-14 md:w-40 2md:w-48 2md:h-16 lg:w-40 xl:h-20 xl:w-48 md:text-2xl 2md:text-3xl lg:text-2xl xl:text-3xl text-white bg-orange-500 bg-opacity-70 rounded-xl hover:text-gray-300 hover:bg-orange-600 hover:scale-110 transition ease-in-out 0.5s delay-150"
      >
        <router-link to="/about">poznaj nas </router-link>
      </div>
    </div>

    <video
      autoplay
      loop
      muted
      class="absolute min-h-screen z-10 w-auto object-cover lg:h-[1100px]"
    >
      <source src="@/assets/mainBgVideo.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </header>
</template>

<script>
import { ref } from "vue";

import HeadLine from "@/components/HeadLine.vue";
import CookieMessage from "@/components/CookieMessage.vue";

export default {
  components: {
    HeadLine,
    CookieMessage,
  },
  setup() {
    const popupTriggers = ref({
      buttonTrigger: false,
      timedTrigger: false,
    });
    // eslint-disable-next-line prettier/prettier
    const TogglePopup = trigger => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };
    setTimeout(() => {
      popupTriggers.value.timedTrigger = true;
    }, 3000);
    return {
      popupTriggers,
      TogglePopup,
    };
  },
};
</script>
