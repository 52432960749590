<template>
  <div>
    <div
      @click="toggleShow"
      class="hover:text-sky-500 transition ease-in-out duration-600 md:hidden absolute top-2 right-1 w-20 h-14"
    >
      <button class="py-3 px-4 pt-2 active:bg-sky-500">
        <i v-if="showMenu" class="fa-solid fa-bars"></i>
        <i v-if="!showMenu" class="fa-solid fa-bars"></i>
      </button>
    </div>
    <transition name="fade">
      <div v-if="showMenu" class="md:hidden">
        <div
          class="flex flex-col md:flex-row md:justify-center z-40 md:px-10 md:pb-0 pb-5 md:static absolute uppercase font-bold text-neutral-900 md:w-screen w-full left-0 top-16 shadow-indigo-500/40 bg-gradient-to-r from-[#ffffff] via-[#8dadcf] to-[#02254d] dark:bg-gradient-to-r dark:from-[#ffffff] md:dark:via-[#737379] dark:via-[#97979d] dark:to-[#181919]"
        >
          <router-link @click="toggleShow" class="p-2" to="/home">
            Home
          </router-link>
          <router-link @click="toggleShow" class="p-2" to="/about"
            >O Firmie</router-link
          >
          <router-link @click="toggleShow" class="p-2" to="/services"
            >Usługi</router-link
          >

          <router-link @click="toggleShow" class="p-2" to="/contact"
            >Kontakt</router-link
          >
          <router-link @click="toggleShow" class="p-2" to="/links"
            >Linki</router-link
          >
        </div>
      </div></transition
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: true,
    };
  },

  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
    this.toggleShow();
  },
};
</script>
<style>
.fade-eneter-from {
  opacity: 1;
  transform: translateX(20);
}
.fade-eneter-to {
  opacity: 0;
  transform: translateX(20);
}
.fade-eneter-active {
  transition: all 0.5s ease-in-out;
}

.fade-leave-from {
  opacity: 1;
  transform: translateX(20);
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(20);
}
.fade-leave-active {
  transition: all 0.8s ease-in-out;
}
</style>
