<template>
  <section class="mb-16">
    <h1
      class="font-bold uppercase tracking-wide leading-10 text-3xl s:text-[35px] md:text-6xl 2md:text-[74px] lg:text-6xl xl:text-8xl mb-14 text-blue-500"
    >
      <p :class="[{ actionClasses }, 'leading-snug']">{{ action }}</p>
      <br /><br />
    </h1>
  </section>
</template>

<script>
import HeroElements from "@/HeroElements";

export default {
  name: "HeadLine",
  data() {
    return {
      action: "Serwis i konserwacja",
      interval: null,
    };
  },
  computed: {
    actionClasses() {
      return {
        [this.action.toLowerCase()]: true,
      };
    },
  },
  created() {
    this.changeTitle();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    changeTitle() {
      this.interval = setInterval(() => {
        const actions = [
          "Serwis i konserwacja",
          "Zarządzanie systemami",
          "Bezpieczestwo danych",
          "Konfiguracja urządzeń",
          "Infrastruktura IT",
          "Wirtualizacja",
          "Inwentaryzacja",
        ];
        this.action = HeroElements(actions, this.action);
      }, 3000);
    },
  },
};
</script>
